<template>
    <main>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Escala de Lawton y Brody</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Escala de Barthel</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="finisRisk-tab" data-toggle="tab" href="#finisRisk" role="tab" aria-controls="finisRisk" aria-selected="false">FINNIS RISK</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="fragmingam-tab" data-toggle="tab" href="#fragmingam" role="tab" aria-controls="fragmingam" aria-selected="false">FRAGMINGAM</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <indexLawton :idHistoria="idHistoria" :finalizada="finalizada" ></indexLawton>
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
             <indexBarthel :idHistoria="idHistoria" :finalizada="finalizada" ></indexBarthel>
        </div>
        <div class="tab-pane fade" id="finisRisk" role="tabpanel" aria-labelledby="finisRisk-tab">
            <indexFinnisRisk :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario" :sexo="sexo" :fecha_nacimiento="fecha_nacimiento"></indexFinnisRisk>
        </div>
        <div class="tab-pane fade" id="fragmingam" role="tabpanel" aria-labelledby="fragmingam-tab">
            <indexFragmingam :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario" :sexo="sexo" :fecha_nacimiento="fecha_nacimiento"></indexFragmingam>
        </div>
        </div>
    
    </main>
    
</template>
<script>
import indexLawton from "../base/baseLawton/indexLawton";
import indexBarthel from "../base/baseBarthel/indexBarthel";
import indexFinnisRisk from "../base/baseFinnisRisk/indexFinnisRisk";
import indexFragmingam from "../base/baseFragmingam/indexFragmingam";

export default {
    props: ['idHistoria','finalizada','idUsuario','sexo','fecha_nacimiento'],
    components:{indexLawton,indexBarthel,indexFinnisRisk,indexFragmingam},
}
</script>

