<template>

    <main ref="resumen" id="resumen">
        <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
        <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
        <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
        <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
        <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div>
        <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
        <div class="py-2"><reporteExamenesShow :idHistoria="idHistoria"></reporteExamenesShow></div>
       <div class="py-2"><situacionesPersonalesShow :idHistoria="idHistoria" :idUsuario="idUsuario"></situacionesPersonalesShow></div>
       <div class="py-2"><finnisRiskShow :idHistoria="idHistoria" :idUsuario="idUsuario"></finnisRiskShow></div>
       <div class="py-2"><fragmingamShow :idHistoria="idHistoria" ></fragmingamShow></div>
    </main>

</template>
<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import reporteExamenesShow from "../../base/baseReporteExamenesAdolescente/reporteExamenesShow";
import situacionesPersonalesShow from "../../base/baseSituacionesPersonales/situacionesPersonalesShow";
import finnisRiskShow from "../../base/baseFinnisRisk/finnisRiskShow";
import fragmingamShow from "../../base/baseFragmingam/fragmingamShow";

export default {
    props: ['idHistoria','idUsuario'],
    components:{motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    exploracionFisicaShow,
    reporteExamenesShow,situacionesPersonalesShow,finnisRiskShow,fragmingamShow},
    data () {
        return {
            imgData : '',
        }
    }
}
</script>
<style scoped>
.resumen-container {
    height:300px;
    overflow-y:scroll
}

</style>